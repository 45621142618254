import { axios } from '@/lib/axios';
import { SubscribeDeckResponse } from '../types';

type SubscribeDeckDTO = {
  channel_id: string
  channel_package_id: string
  device_os: string,
  source: string,
  discounted_amount: string,
  discount_coupon: string,
  final_amount_paid: string,
  utm: string
}

export const subscribeDeck = (data: SubscribeDeckDTO, token: string, params: string): Promise<SubscribeDeckResponse> => {
  return axios.post(`/channels/channels/subscribe?subscription_source=${params}`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
};
