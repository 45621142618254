import { Header } from '@/components/Elements';
import React, { useEffect, useState } from 'react';
import { LetsGetStarted, PaymentStep, OtpVerificationStep } from '@/features/decks';
import { useDecks } from '../api/getDecks';
import {useLocation, useParams} from 'react-router-dom';
import { ChannelPackage, Deck } from '../types';
import { Helmet } from 'react-helmet-async';
import { purchaseApi } from "@/features/decks/api/purchase";
import { usePaymentFlowStore } from "@/features/decks/stores/payment_flow";
import { getQueryParam } from '@/utils/utility';
import PlanSelection from '../components/PlanSelection';
import { isMobile } from 'react-device-detect';


export function Purchase() {
  const { deckId, packageId } = useParams()
  const { data } = useDecks({ config: {} });
  const location = useLocation();
  const isNewDeck = location.state?.isNewDeck;
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  const [deck, setDeck] = useState<Deck | undefined>(undefined)
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState<any>({});
  const [isCouponValid, setIsCouponValid] = useState('');
  const { token } = usePaymentFlowStore()
  const [channelPackage, setChannelPackage] = useState<ChannelPackage | undefined>(undefined);
  const [selectedPlan, setSelectedPlan] = useState<null | any>(null);
  const [choosePlanData, setChoosePlanData] = useState<any>(channelPackage);

  const coupon = getQueryParam('coupon');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [coupon]);

  useEffect(() => {
    if (data?.length) {
      const d = data.find((item : any) => item.id === deckId);
      setDeck(d)
      const filterdTimeBased = d?.channel_packages?.filter((item: any) => item.id === packageId);
      setChannelPackage(filterdTimeBased[0])
      setSelectedPlan(filterdTimeBased[0].id);
      setChoosePlanData(filterdTimeBased[0]);


      if (coupon) {
      setCouponCode(coupon);
      handleApplyCoupon(null);
    }


    }
  }, [data, coupon]);


  const handleApplyCoupon = async (e: any) => {
    if (couponCode === '') { return }
    e?.preventDefault()
    try {
      const result = await purchaseApi({
        coupon_code: couponCode,
        channel_package_id: choosePlanData ? choosePlanData?.id! : channelPackage?.id!,
      }, token!)
      if (result?.discount_details?.final_amount) {
        setCouponDiscount(result);
        setIsCouponValid('true')
      } else {
        setIsCouponValid('false')
      }
    } catch (error) {
      setIsCouponValid('false')
    }
  };

  const onPressSelectPlan = (plan:any) => {
    setSelectedPlan(plan.id);
    setChoosePlanData(plan);
  };

  const onPressCross = () => {
    setCouponCode('')
    setCouponDiscount({})
    setIsCouponValid('')
  };

  const getPricingInfo = () => {
    if (choosePlanData?.plan_type) {
      return `${choosePlanData?.plan_type}`
    } else {
      return `${choosePlanData?.num_of_cards} Stock Picks`
    }
  }

  return (
    <section className='bg-light deck-purchase' >
      <Header />
      <Helmet>
        <title>Purchasing Deck {deck?.title || ""}</title>
      </Helmet>
      <div className="container-fluid container-md p-0 p-md-5">
        <div className="row flex-wrap">
          <div className="col-12 mb-3 col-md-4 order-md-2 text-wrap">
            {isMobile && isNewDeck ? (
           <>
             <div className="bg-white px-4 pt-4 py-4">
               <h4 className='fs-18' >Choose Your Plan Type</h4>
               <PlanSelection
                 selectedPlan={selectedPlan}
                 deck={deck}
                 isMobile={isMobile}
                 onPressSelectPlan={onPressSelectPlan}
               />
             </div>
             <br/>
           </>
            ) : null}
            <div className="bg-white p-4 purchase-step">
              <h4 className='mb-4 fs-18' >Purchase Information</h4>
              {/* <br /> */}
              <div className='d-flex justify-content-between no-arrow'  >
                <p className='fs-14' >Plan Name</p>
                <p className='text-green fw-medium fs-14' >{deck?.title}</p>
              </div>
              {/*<div className='d-flex justify-content-between no-arrow mt-3'  >*/}
              {/*  <p className='fs-14' >Description</p>*/}
              {/*  <p className='fw-medium fs-14' >{deck?.short_description || '---'}</p>*/}
              {/*</div>*/}
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className='fs-14' >Plan Type</p>
                <p className='fw-medium fs-14' >
                  {choosePlanData ? getPricingInfo() : channelPackage?.plan_type || channelPackage?.num_of_cards + ' ' + 'Stock Picks'}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className='fs-14' >Amount</p>
                <p className='fw-medium fs-14' >
                  &#8377; {choosePlanData ? choosePlanData.price : channelPackage?.price}/-
                </p>
              </div>
              <div className="col align-items-center justify-content-between mt-3">
                <p className="fs-14">Apply Coupon</p>
                <div className="d-flex align-items-center justify-content-between mt-2" style={{ border: '1px dotted #847C7C', borderRadius: 6, padding: '10px 15px' }}>
                  <input type="text"
                    value={couponCode}
                    onChange={(e) => {
                      setIsCouponValid('')
                      setCouponCode(e.target.value)
                    }}
                    placeholder="Enter Coupon code"
                    style={{ fontSize: 13, fontWeight: '400', color: '#6F7D83', border: 'none', outline: 'none', display: 'flex', minWidth: 0 }} />

                  {
                    isCouponValid === 'true' ? (
                      <button className="d-flex justify-content-center align-items-center border-0 bg-transparent" style={{ height: 20, width: 20, marginLeft: '10px' }} onClick={onPressCross}>
                        <i className="bi bi-x-lg" style={{ fontSize: '15px' }}></i>
                      </button>
                    ) : (
                      <button
                        onClick={handleApplyCoupon}
                        style={{ fontSize: 13, fontWeight: 500, color: '#6F7D83', background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px', whiteSpace: 'nowrap' }}
                      >
                        Apply
                      </button>
                    )
                  }

                </div>
                <p className="fw-medium fs-12" style={{ color: isCouponValid === 'true' ? '#00B948' : isCouponValid === 'false' ? '#F75459' : '' }}>
                  {isCouponValid === 'true' ? `Code applied successfully. ₹${couponDiscount?.discount_details?.discounted_amount} saved` : isCouponValid === 'false' ? 'Code is invalid' : ''}
                </p>
              </div>

              <div className="my-3"></div>
              <div className="d-flex align-items-baseline justify-content-between">
                <div>
                  <h4 className='fs-18' >Total:</h4>
                  <p className='fs-14' >(Incl. 18% GST)</p>
                </div>
                <h4 className="fs-18 text-green">&#8377; {isCouponValid === 'true' ? couponDiscount?.discount_details?.final_amount : choosePlanData ? choosePlanData.price : channelPackage?.price}/-</h4>
              </div>
              <div className="d-none d-md-block">
                <PaymentStep channel_id={deck?.id} channel_package_id={choosePlanData ? choosePlanData?.id : channelPackage?.id} couponDiscount={{ ...couponDiscount, couponCode }} subdomain={subdomain}/>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 order-md-1">
            <div className="accordion mt-1" id="Steps">
              {!isMobile && isNewDeck ? (
                <>
                  <PlanSelection
                    selectedPlan={selectedPlan}
                    deck={deck}
                    onPressSelectPlan={onPressSelectPlan}
                    isMobile={isMobile}
                  />
                  <br/>

                </>
              ) : null}
              <LetsGetStarted isMobile={isMobile} />
              <br />
              <OtpVerificationStep isMobile={isMobile} />
              <br />
              <div className="d-md-none">
                <PaymentStep channel_id={deck?.id} channel_package_id={choosePlanData ? choosePlanData?.id : channelPackage?.id} couponDiscount={{ ...couponDiscount, couponCode }} subdomain={subdomain}/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
